import React, { useState, useEffect } from 'react';

import {
  CCard,
  CCardBody,
  CDataTable
} from '@coreui/react';

const UserMessages = ({ user, setHeader }) => {
  useEffect(() => {
    if (setHeader) {
      setHeader('User Messages');
    }
  });

  return (
    <CDataTable />
  )
}

export default UserMessages;