import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth, ProvideAuth } from '../services/auth'
import * as ROUTES from '../constants/routes';
import { useInputChange } from '../services/forms';

import {
  CContainer,
  CButton,
  CCard,
  CCardBody,
  CRow,
  CCol,
  CCardGroup,
  CSpinner,
  CForm,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInput,
  CAlert
} from '@coreui/react';

import CIcon from '@coreui/icons-react'

const InitialFormState = {
  email: '',
  password: ''
};

const LoginInError = (props) => (
  <CRow>
    <CCol xs="12">
      <CAlert color="danger">
        Error logging in.  Please double check your credentials.
      </CAlert>
    </CCol>
  </CRow>
);

const LoginForm = () => {
  const auth = useAuth();
  
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [input, handleInputChange] = useInputChange({}, () => setError(false));

  const login = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const user = await auth.signin(input.email, input.password);
      if (user) {
        const query = new URLSearchParams(location.search);
        const redirect = query.get('redirect');
        
        if (redirect) {
          return history.push(redirect);
        }

        return history.push(ROUTES.DEFAULT);
      }
    } catch(error) {
      setError(error);
    }

    setLoading(false);
  }

  return (
    <CForm onSubmit={login}>
      <h1>Login</h1>
      <p className="text-muted">Sign In to your account</p>
      <CInputGroup className="mb-3">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon name="cil-user" />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput
          type="text"
          placeholder="Email"
          autoComplete="email"
          name="email"
          onChange={handleInputChange}
          value={input.email || ""}
          onFocus={(e) => e.target.value = ""}
        />
      </CInputGroup>
      <CInputGroup className="mb-4">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon name="cil-lock-locked" />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          name="password"
          onChange={handleInputChange}
          value={input.password || ""}
          onFocus={(e) => e.target.value = ""}
        />
      </CInputGroup>
      {error && <LoginInError error={error} />}
      <CRow>
        <CCol sm="6">
          <CButton disabled={loading} color="info" type="submit">
            Sign In
            {loading ? <CSpinner size="sm" className="ml-2" /> : ""}
            </CButton>
        </CCol>
      </CRow>

    </CForm>
  )
}

const Login = () => (
  <ProvideAuth noUserOk={true}>
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <LoginForm />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </ProvideAuth>
);

export default Login;