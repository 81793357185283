import React, { useState, useEffect } from 'react';
import { Route, Redirect, Link, Switch } from 'react-router-dom';

import { getUser } from '../../services/users';
import * as ROUTES from '../../constants/routes';

import UserMessages from './UserMessages';
import UserInfo from './UserInfo';
import UserTransactions from './UserTransactions';

import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CFade,
  CSpinner,
  CNav,
  CNavLink
} from '@coreui/react';

const UserDetail = (props) => {
  const uid = props.match.params.userId;

  // Try to load initial user state from props...
  const [user, setUser] = useState(props.user || null);
  const [header, setHeader] = useState('');
  
  useEffect(() => {
    if (!user) {
      // Lookup user
      console.log(`Looking up user ${uid}`);

      getUser(uid).then((user) => {
        if (user) {
          return setUser(user);
        }
        console.log("No user found");
        return setUser(false);
      }).catch((err) => {
        console.error("Error looking up user", err);
        return setUser(false);
      });
    }
  }, [uid]);

  if (user) {
    return (
      <CFade>
        <CRow>
          <CCol sm={6} lg={4}>
            <CCard>
              <CCardHeader>User Actions</CCardHeader>
              <CCardBody>
                <CNav vertical>
                  <CNavLink to={`${props.match.url}/messages`}>Messages</CNavLink>
                  <CNavLink to={`${props.match.url}/transactions`}>Transactions</CNavLink>
                </CNav>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>User Details</CCardHeader>
              <CCardBody>
                <UserInfo user={user} />
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm={6} lg={8}>
            <CCard>
              <CCardHeader>{header}</CCardHeader>
              <CCardBody>
                <Switch>

                  <Route exact path={`${props.match.path}/messages`} render={props =>
                    <UserMessages {...props} user={user} setHeader={setHeader} />}
                  />

                  <Route exact path={`${props.match.path}/transactions`} render={props =>
                    <UserTransactions {...props} user={user} setHeader={setHeader} />}
                  />

                  <Route exact path={props.match.path}>
                    <Redirect to={`${user.uid}/messages`} />
                  </Route>

                </Switch>
              </CCardBody>
            </CCard>

          </CCol>
        </CRow>
      </CFade>
    )
  } else if (user === false) {
    return (
      <CRow className="justify-content-center">
        <CCol sm={6} lg={4}>
          <CCard>
            <CCardBody>
              Invalid user.  Click <Link to={ROUTES.USERS}>here</Link> to search again.
              </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }

  //Loading
  return (
    <CRow className="justify-content-center">
      <CSpinner color="info" />
    </CRow>
  );
}


export default UserDetail;