import React from 'react';
import { Route } from 'react-router-dom';

import { ProvideAuth } from '../services/auth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <ProvideAuth>
      <Route {...rest} render={
        props => {
          return <Component {...rest} {...props} />
        }
      } />
    </ProvideAuth>
  );
}

export default ProtectedRoute;
