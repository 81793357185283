import React from 'react';

import Chrome from '../../components/Chrome';
import { app as firebase, useFirestoreQuery } from '../../services/firebase';
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from '@coreui/react';


const EmailLists = () => {
  const {status, data, error} = useFirestoreQuery(
    firebase.firestore().collection('email_lists')
  );

  return (
    <Chrome>
      <CCol>
        <CCard>
          
          <CCardHeader>
            <strong>Email Lists</strong>
          </CCardHeader>
          
          <CCardBody>
            <CDataTable
              
            />
          </CCardBody>

        </CCard>
      </CCol>
    </Chrome>
  )
}

export default EmailLists;
