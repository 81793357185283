import React, { useState, useEffect } from 'react';

import {
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CForm,
  CFormGroup,
  CInput,
  CTextarea,
  CSpinner,
  CSwitch,
  CRow,
  CCol
} from '@coreui/react';

import { callable } from '../../services/firebase';
import { useInputChange } from '../../services/forms';

import * as ObjectsToCsv from 'objects-to-csv';

const downloadReport = async (report, format) => {
  const element = document.createElement('a');
  const now = (new Date()).toISOString().split('T')[0].replace(/-/g,'');
  const fileName = `${now}-${report.eventId}-${report.reportType}.${format}`;
  
  element.setAttribute('download', fileName);
  element.style.display = 'none';

  if (format === 'csv') {
    const csv = new ObjectsToCsv(report.raw.rows);
    const data = encodeURIComponent(await csv.toString());
    //console.log(csvString);
    //const data = encodeURIComponent(report.csv);
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + data);
  } else if (format === 'json') {
    const data = JSON.stringify(report.raw.rows, null, 2);
    element.setAttribute('href', 'data:application/json;charset=utf-8,' + data);
  } else {
    console.error(`Invalid report output format: ${format}`);
    return;
  }
  
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const DownloadButton = (props) => {
  if (props.report) {
    return (
      <CDropdown className={props.className}>
        <CDropdownToggle color="success" className="btn-sm py-0">
          Download
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem onClick={props.downloadCsv}>CSV</CDropdownItem>
          <CDropdownItem onClick={props.downloadJson}>JSON</CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  }

  return <></>;
}

const DEFAULT_VALUES = {itemIds: ''};

const UploadButton = (props) => {
  const { report }  = props;

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [form, updateForm] = useInputChange(DEFAULT_VALUES);

  const toggle = () => {
    setModal(!modal);
  }

  const updateShipping = async (evt) => {
    evt.preventDefault();
    const formEl = evt.target;

    setIsLoading(true);

    const itemIds = form.itemIds.trim().split('\n').map(item => item.trim());

    const res = await callable('reports-updateShipping', {
      eventId: report.eventId,
      itemIds: itemIds
    });

    setIsLoading(false);

    if (!res.error) {
      alert("Shipping records successfully updated!");
      formEl.reset();
    } else {
      alert(`Error updating shipping records: ${res.error.message}`);
    }

    setModal(!modal);
  }

  if (props.report) {
    return (
      <div className="card-header-actions m-0 mr-2 btn-group">
        <CButton
          color="info"
          onClick={toggle}
          className="btn-sm py-0"
        >
          Record Shipping
        </CButton>
        
        <CModal
          show={modal}
          onClose={toggle}
        >
          <CModalHeader closeButton>
            <strong>Record Shipping</strong>
          </CModalHeader>

          <CModalBody>
            <CForm
              onSubmit={updateShipping}
            >
              <CFormGroup>
                <p>Use the textarea below to enter the IDs of items that have been shipped.  Enter one ID per line.</p>
              </CFormGroup>
              
              <CFormGroup>
                <CTextarea
                  name="itemIds"
                  onChange={updateForm}
                  data-lpignore={true}
                />
              </CFormGroup>

              <CFormGroup className="form-actions mb-0 pt-2">
                <CButton
                  type="submit"
                  color="info"
                  disabled={isLoading}
                >
                  { isLoading ?
                    <CSpinner size="sm" style={{marginRight: '10px'}}/> : ""
                  }
                  Submit
                </CButton>
              </CFormGroup>
            </CForm>
          </CModalBody>

          <CModalFooter></CModalFooter>
        </CModal>
      </div>
    )
  }
  return <></>;
}

const FilterButton = (props) => {
  const [enabled, setEnabled] = useState(false);

  const handler = () => {
    setEnabled(!enabled);

    console.log(`Enabled: ${!enabled}`);

    if (props.onToggle) {
      props.onToggle(!enabled);
    }
  };

  return (
    <CButton
      block
      shape="pill"
      color={enabled ? 'info' : 'light'}
      size="sm"
      onClick={handler}
    >{props.text}</CButton>
  )
};

const FilterButtons = (props) => {
  const { report, setReport } = props;

  const filterTable = (flag, enabled) => {
    if (!report.filters) {
      report.filters = {}
    }

    if (enabled) {
      report.filters[flag] = true;
    } else {
      delete report.filters[flag];
    }

    if (!report.raw.originalRows) {
      report.raw.originalRows = report.raw.rows;
    }

    report.raw.rows = report.raw.originalRows.filter((row) => {
      if (report.filters.address) {
        if (!row.addressLine1) {
          return false;
        }
      }

      if (report.filters.shipped) {
        if (row.status === 'shipped')  {
          return false;
        }
      }

      return true;
    });

    setReport({...report});

    console.log(report, flag, enabled);
  }

  //const [excludeShipped, setExcludeShipped] = useState(false);

  if (report && report.reportType === 'eventShippableItems') {
    return (
      <CRow className="align-items-center">
        <CCol sm="2" className="mb-3">
          <FilterButton
            text="Exclude Shipped"
            onToggle={(enabled) => filterTable('shipped', enabled)}
          />
        </CCol>
        <CCol sm="2" className="mb-3">
          <FilterButton
            text="Exclude No Address"
            onToggle={(enabled) => filterTable('address', enabled)}
          />
        </CCol>
      </CRow>
    )
  }

  return <></>
}

const ReportOutput = ({ report, loading, setReport }) => {
  console.log('table:', report.raw);

  return (
    <CCard>

      <CCardHeader>
        <strong>Report Output</strong>

        <DownloadButton
          className="card-header-actions m-0 btn-group"
          downloadCsv={() => {downloadReport(report, 'csv')}}
          downloadJson={() => {downloadReport(report, 'json')}}
          report={report}
        />

        {report && report.reportType === 'eventShippableItems' ?
          <UploadButton report={report} /> : <></>
        }

      </CCardHeader>

      <CCardBody>
        <FilterButtons report={report} setReport={setReport} />

        {report.raw.headers ? <CDataTable fields={report.raw.headers} items={report.raw.rows} /> : (
          loading ?
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            :
            <p>No report data.</p>
        )}
      </CCardBody>

    </CCard>
  );
};

export default ReportOutput;
