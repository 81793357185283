import React, { useState } from 'react';

import { getUser } from '../../services/users';

import {
  CForm, CFormGroup, CInputGroup, CInput, CInputGroupAppend, CButton
} from '@coreui/react';

import { useInputChange } from '../../services/forms'

const USERS = {
  '123': {
    uid: '123',
    displayName: 'bob'
  }
}

const UserLookup = ({ setUser }) => {
  const [form, setInput] = useInputChange();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (evt) => {
    evt.preventDefault();

    setLoading(true);

    try {
      const user = await getUser(form.userQuery);
      
      console.log(user);
      
      if (user) {
        return setUser(user);
      }
    } catch (err) {
      // handle error
      alert('Error');
    }

    setLoading(false);

    /*
    setTimeout(() => {
      const user = USERS[form.userQuery];
      
      setLoading(false);
      
      if (user) {
        setUser(user);
      }
    }, 2000);
    */
  }

  return (
    <CForm onSubmit={onSubmit}>
      <CFormGroup>
        <CInputGroup>
          <CInput
            name="userQuery"
            type="text"
            placeholder="username, email or uid"
            onChange={setInput}
          />
          <CInputGroupAppend>
            <CButton type="submit" color="info" disabled={loading}>Go</CButton>
          </CInputGroupAppend>
        </CInputGroup>
      </CFormGroup>
    </CForm>
  );
}

export default UserLookup;
