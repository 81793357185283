//@ts-check
import React, { useState, useEffect, useContext, createContext, useReducer, useRef } from "react";

import * as firebase from "firebase/app";
import "firebase/auth";
import 'firebase/functions';
import 'firebase/firestore';

/**
 * @typedef {}
 */

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const app = firebase.initializeApp(config);

const firebaseContext = createContext(null);

export function ProvideFirebase({ children }) {
  const firebase = useProvideFirebase();

  return (
    <firebaseContext.Provider value={firebase}>
      {children}
    </firebaseContext.Provider>
  )
}

/**
 * Call a Cloud Function named `name` with parameters `data`.
 * This method will safely call the Cloud Function, if the function
 * call fails, the error will be caught and returned in the result.
 * 
 * @typedef {object} CallableResult
 * @property {any=} data Data returned from the function
 * @property {firebase.functions.HttpsError=} error Any error thrown by the function
 * 
 * @param {string} name Name of HTTPS Callable function
 * @param {any} data Data to pass to function
 * @returns {Promise<CallableResult>}
 */
export const callable = async (name, data) => {
  const func = app.functions().httpsCallable(name);

  try {
    return await func(data);
  } catch (error) {
    return { error };
  }
}

export const useFirebase = () => {
  return useContext(firebaseContext);
};

const useProvideFirebase = () => {
  const [user, setUser] = useState(null);

  const signin = (email, password) => {
    const fn = app.functions().httpsCallable('auth-maxLogin');
    
    return fn({email, password})
    .then(response => {
      const token = response.data.token;
      if (!token) {
        throw new Error('No token');
      }
    
      return app.auth()
        .signInWithCustomToken(token)
        .then((user) => {
          setUser(user);
          return response.user;
        }
      );

    });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any
  // component that utilizes this hook to re-render with the
  // latest auth object.
  useEffect(() => {
    console.log('onAuthStateChanged subscribe');

    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      // User is logged in
      if (user) {
        return setUser(user);  
      }
      
      // User is not logged in
      setUser(false); 
    });

    // Cleanup subscription on unmount
    return () => {
      console.log('onAuthStateChanged unsubscribe');
      unsubscribe();
    }
  }, []);

  return {
    app,
    user,
    signin,
    signout
  };
}

// Reducer for hook state and actions
const reducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", data: undefined, error: undefined };
    case "loading":
      return { status: "loading", data: undefined, error: undefined };
    case "success":
      return { status: "success", data: action.payload, error: undefined };
    case "error":
      return { status: "error", data: undefined, error: action.payload };
    default:
      throw new Error("invalid action");
  }
}

function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

// Hook
export const useFirestoreQuery = (query) => {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useFirestoreQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = { 
    status: query ? "loading" : "idle", 
    data: undefined, 
    error: undefined 
  };
  
  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);
  
  // Get cached Firestore query object with useMemoCompare (https://usehooks.com/useMemoCompare)
  // Needed because firestore.collection("profiles").doc(uid) will always being a new object reference
  // causing effect to run -> state change -> rerender -> effect runs -> etc ...
  // This is nicer than requiring hook consumer to always memoize query with useMemo.
  const queryCached = useMemoCompare(query, prevQuery => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }
    
    dispatch({ type: "loading" });
    
    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return queryCached.onSnapshot(
      response => {
        console.log('onSnapshot');
        
        // Get data for collection or doc
        const data = response.docs
          ? getCollectionData(response)
          : getDocData(response);
        
        dispatch({ type: "success", payload: data });
      },
      error => {
        dispatch({ type: "error", payload: error });
      }
    );
    
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}

// Get doc data and merge doc.id
function getDocData(doc) {
  return doc.exists === true ? { id: doc.id, ...doc.data() } : null;
}

// Get array of doc data from collection
function getCollectionData(collection) {
  return collection.docs.map(getDocData);
}

