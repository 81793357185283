import React, { useState, useEffect, useMemo } from 'react';

import { DateTime } from "luxon";

import { app as firebase, useFirestoreQuery } from '../../services/firebase';

import { firestore } from "firebase/app";

import {
  CCard,
  CCardBody,
  CDataTable,
  CPopover
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

const capitalize = (str) => {
  const words = str.split(/[ _]+/g);
  for (const idx in words) {
    const word = words[idx];
    words[idx] = word[0].toUpperCase() + word.slice(1);
  }
  return words.join(' ');
}

const getEvents = async (eventIds) => {
  const events = {};

  console.log(`Loading ${eventIds.length} events`);

  const chunkSize = 10;
  for (let i = 0; i < eventIds.length; i += chunkSize) {
    const chunk = eventIds.slice(i, i + chunkSize);

    const query = await firebase
      .firestore()
      .collection('events')
      .where(firestore.FieldPath.documentId(), 'in', chunk)
      .get();

    query.forEach(doc => {
      events[doc.id] = doc;
    });
  }

  return events;
}

const getTransactions = async (uid) => {
  console.log("Loading transactions");

  const transactions = await firebase
    .firestore()
    .collection('transactions')
    .where('uid', '==', uid)
    .orderBy('created', 'desc')
    .get();

  console.log(`Loaded ${transactions.size} transactions`);

  const eventIds = transactions.docs.reduce((c, doc) => {
    const eventId = doc.get('paymentItem.eventId');
    if (eventId && c.indexOf(eventId) < 0) {
      c.push(eventId);
    }

    return c;
  }, []);

  const events = await getEvents(eventIds);

  return { transactions, events };
}

const Loading = () => (
  <p>Loading</p>
);

const UserTransactions = ({ user, setHeader }) => {
  console.log('UserTransactions render');

  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (setHeader) {
      setHeader('User Transactions');
    }
  });

  useEffect(() => {
    if (loading === null && !transactions) {
      setLoading(true);

      getTransactions(user.uid).then(({ transactions, events }) => {
        const rows = [];

        transactions.forEach((doc) => {
          let amount = doc.get('amount');
          if (doc.get('paymentItem.bookAmount')) {
            amount = doc.get('paymentItem.bookAmount');
          }

          if (amount) {
            amount = "$" + (amount / 100);
          }

          const eventId = doc.get('paymentItem.eventId');

          rows.push({
            id: doc.id,
            amount: amount,
            created: DateTime.fromJSDate(doc.get('created').toDate()).toLocaleString(DateTime.DATETIME_MED),
            item: capitalize(doc.get('paymentItem.type')),
            source: doc.get('source.card.brand'),
            card: doc.get('source.card'),
            eventName: events[eventId].get('title'),
            //eventId: doc.event.id,
            raw: doc.data()
          });
        });

        setTransactions(rows);

        setLoading(false);
      });
    }
  }, [user.uid]);

  return (
    <CDataTable
      fields={['item', 'amount', 'source', {key: 'eventName', label: 'Event'}, 'created']}
      items={transactions}
      loading={loading}
      loadingSlot={<Loading/>}
      noItemsViewSlot={(<></>)}
      scopedSlots={{
        source: (item) => {
          if (item.raw.voucherId) {
            return (
              <td>
                <CPopover
                  placement='top'
                  content={`Voucher: ${item.raw.voucherId}`}
                  interactive={true}
                  trigger="click"
                >
                  {/* USE: cil-transfer */}
                  {/*<span style={{ cursor: 'pointer' }}>Voucher</span>*/}
                  <CIcon style={{ cursor: 'pointer' }} name="cil-transfer" size="xl" />
                </CPopover>
              </td>
            )
          }

          if (!item.source) {
            return (<td></td>)
          }

          return (
            <td>
              <CPopover
                placement='top'
                content={`Ending in ${item.card.last4}`}
                interactive={true}
                trigger="click"
              >
                <CIcon style={{ cursor: 'pointer' }} name={("cib-cc-" + item.source)} size="xl" />
              </CPopover>
            </td>)
        }
      }}
    />
  )
}

export default UserTransactions;
