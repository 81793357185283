import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';

import './assets/scss/style.scss';
import { icons } from './assets/icons'

import * as ROUTES from './constants/routes';

import { ProvideFirebase } from './services/firebase';

import ProtectedRoute from './components/ProtectedRoute';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Reports from './pages/Reports';
import User from './pages/User';
import EmailLists from './pages/EmailLists';

React.icons = icons;

function App() {
  return (
    <ProvideFirebase>
      <div className="App">
        <Router>
          <ProtectedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          <ProtectedRoute exact path={ROUTES.REPORTS} component={Reports} />
          <ProtectedRoute path={ROUTES.USERS} component={User} />
          <ProtectedRoute path={ROUTES.EMAIL_LISTS} component={EmailLists} />
          
          
          <Route exact path={ROUTES.LOGIN} component={Login} />

        </Router>
      </div>
    </ProvideFirebase>
  );
}

export default App;
