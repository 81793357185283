import { app as firebase } from './firebase';

export const getUser = async (query) => {
    const getUser = firebase.functions().httpsCallable('reports-getUser');
    try {
        const response = await getUser({ value: query });
        if (response.data.user) {
            return {
                ...response.data.user,
                ...response.data.profile
            };
        }
    } catch (error) {
        console.log(`Error getting user from query '${query}'`, error);
    }
}
