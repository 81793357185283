import {
    cilDescription,
    cilLockLocked,
    cilUser,
    cilSend,
    cilChatBubble,
    cilInfo,
    cilCheckCircle,
    cilXCircle,
    cilCaretTop,
    cilCaretBottom,
    cilChevronTop,
    cilChevronBottom,
    cilTransfer,
    cibCcVisa,
    cibCcMastercard,
    cibCcAmex,
    cibCcDiscover,
    cibCcDinersClub,
    cibCcJcb,
    cibCcApplePay
} from '@coreui/icons'

export const icons = {
    cilDescription,
    cilLockLocked,
    cilUser,
    cilChatBubble,
    cilInfo,
    cilSend,
    cilCheckCircle,
    cilXCircle,
    cilCaretTop,
    cilCaretBottom,
    cilChevronTop,
    cilChevronBottom,
    cilTransfer,
    cibCcVisa,
    cibCcMastercard,
    cibCcAmex,
    cibCcDiscover,
    cibCcDinersClub,
    cibCcJcb,
    cibCcApplePay
}
