import React, { useState, useEffect } from 'react';
import { useAuth } from '../services/auth'

import Chrome from '../components/Chrome';

import {
  CCard,
  CCardBody,
  CButton
} from '@coreui/react';

const Dashboard = () => {
  console.log("Dashboard");

  const auth = useAuth();

  return (
    <Chrome>
      <CCard>
        <CCardBody>
          <p>Dashboard, {auth.user.displayName} ({auth.user.uid})</p>
          <CButton color="info" onClick={auth.signout}>Sign Out</CButton>
        </CCardBody>
      </CCard>
    </Chrome>
  );
}

export default Dashboard;