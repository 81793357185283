import React, { useState } from 'react';
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CHeader,
  CToggler,
  CHeaderBrand,
  CContainer,
  CFade
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { app as firebase } from '../services/firebase';

import * as ROUTES from '../constants/routes';

const Chrome = (props) => {
  const [showNav, setShowNav] = useState(false);

  return (
    <div className="c-app c-default-layout">
      <CSidebar show={showNav}>
        <CSidebarBrand></CSidebarBrand>

        <CSidebarNav>
          <CSidebarNavItem
            name="Users"
            to={ROUTES.USERS}
            icon="cil-user"
          />

          <CSidebarNavItem
            name="Reports"
            to={ROUTES.REPORTS}
            icon="cil-description"
          />

          <CSidebarNavItem
            name="Email Lists"
            to={ROUTES.EMAIL_LISTS}
            icon="cil-send"
          />

          <CSidebarNavDivider />

          <CSidebarNavTitle>Account</CSidebarNavTitle>

          <CSidebarNavItem
            name="Logout"
            onClick={() => {
              firebase.auth().signOut();
            }}
            icon="cil-description"
          />

        </CSidebarNav>

      </CSidebar>
      
      <div className="c-wrapper">
        <CHeader className="px-3">
          <CToggler
            inHeader
            className="ml-md-3 d-lg-none"
            onClick={() => setShowNav(!showNav)}
          />
          <CToggler
            inHeader
            className="ml-3 d-md-down-none"
            onClick={() => setShowNav(!showNav)}
          />

          <CHeaderBrand className="mb-0 h6 px-3 mr-auto">
            Reporting &amp; User Management System
          </CHeaderBrand>
        </CHeader>

        <div className="c-body">
          <main className="c-main">
            <CContainer fluid>
              <CFade>
                {props.children}
              </CFade>
            </CContainer>
          </main>
        </div>

      </div>
    </div>
  )
}


export default Chrome;