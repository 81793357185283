import React, { useState, useEffect, useContext, createContext } from "react";
import { Redirect, useLocation } from 'react-router-dom';

import { app as firebase } from './firebase';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

export const ProvideAuth = (props) => {
  const auth = useProvideAuth();
  const location = useLocation();

  //console.log(`ProvideAuth: ${JSON.stringify(location)}`);
  //console.log(props.noUserOk);

  //
  // Only return the children elements if the auth
  // state is known:
  //   object -> logged in
  //   false  -> not logged in
  //   null   -> unknown (loading state)
  //
  if (auth.user || props.noUserOk) {
    // TODO: Can add a method to test the user's level and restrict access
    return (
      <authContext.Provider value={auth}>
        {props.children}
      </authContext.Provider>
    );
  } else if (auth.user === false && location.pathname != '/login') {
    //console.log('redirect', location.pathname);
    return <Redirect to={
      {
        pathname: '/login',
        search: `?redirect=${location.pathname}`,
        state: {
          from: props.location
        }
      }
    } />
  }
  
  // Auth hasn't finished loading yet
  return <></>
}

const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  const signin = async (email, password) => {
    const fn = firebase.functions().httpsCallable('auth-maxLogin');
    
    
    const response = await fn({email, password});
    const token = response.data.token;
    
    if (!token) {
      throw new Error('No token');
    }

    const user = await await firebase.auth().signInWithCustomToken(token);
    if (user) {
      setUser(user);
      return user;
    }
    
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any
  // component that utilizes this hook to re-render with the
  // latest auth object.
  useEffect(() => {
    console.log('onAuthStateChanged subscribe');

    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      // User is logged in
      if (user) {
        return setUser(user);  
      }
      
      // User is not logged in
      setUser(false); 
    });

    // Cleanup subscription on unmount
    return () => {
      console.log('onAuthStateChanged unsubscribe');
      unsubscribe();
    }
  }, []);

  return {
    user,
    signin,
    signout,
  };
}