import React, { useState, useEffect } from 'react';

import {
  CCard,
  CCardBody,
  CDataTable
} from '@coreui/react';

const UserInfo = (props) => {
  const { user } = props;

  const rows = [];
  if (user) {
    rows.push({ key: 'Name', value: user.displayName });
    rows.push({ key: 'Email', value: user.email });
    rows.push({ key: 'Username', value: user.username });
    rows.push({ key: 'Phone', value: user.phoneNumber });
    rows.push({ key: 'UID', value: user.uid });
    rows.push({ key: 'Created', value: user.metadata.creationTime });
    rows.push({ key: 'Active', value: user.metadata.lastSignInTime });
  }

  return (
    <CDataTable
      header={false}
      responsive={true}
      fields={['key', 'value']}
      border={false}
      outlined={false}
      striped={true}
      items={rows}
      //scopedSlots={{ value: this.formatRow }}
      //loading={userTableLoading}
      noItemsViewSlot={(<div className="mb-4"></div>)}
    />
  )
}

export default UserInfo;