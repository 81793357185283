import React, { useState, useEffect } from 'react';

import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody
} from '@coreui/react';

import Chrome from '../../components/Chrome';
import ReportControls from './ReportControls';
import ReportOutput from './ReportOutput';

const Reports = () => {
  const [report, setReport] = useState({raw: {headers: false, rows: []}});
  const [loading, setLoading] = useState(false);

  return (
    <Chrome>
      <CRow className="justify-content-center">
        
        <CCol sm={6} lg={3}>
          <ReportControls setReport={setReport} loading={loading} setLoading={setLoading} />
        </CCol>

        <CCol sm={6} lg={9}>
          <ReportOutput report={report} setReport={setReport} loading={loading} />
        </CCol>

      </CRow>
    </Chrome>
  );
}

export default Reports;