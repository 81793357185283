import React, { useState } from 'react';

export const useInputChange = (initialState, handle) => {
  const [input, setInput] = useState(initialState)

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });

    if (typeof (handle) == 'function') {
      handle(input, e);
    }
  };

  return [input, handleInputChange];
};
