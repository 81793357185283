import React, { useState } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';

import UserLookup from './UserLookup';

import Chrome from '../../components/Chrome';
import UserDetail from './UserDetail';

import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody
} from '@coreui/react';

const UserIndex = (props) => {
  return (
    <CRow className="justify-content-center">
      <CCol sm={6} lg={4}>
        <CCard>
          <CCardHeader>
            <strong>User</strong>
          </CCardHeader>
          <CCardBody>
            <UserLookup setUser={props.setUser} />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

const User = ({ match }) => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  const doSetUser = (user) => {
    const path = `${match.path}/${user.uid}`;
    setUser(user);
    return history.push(path);
  }

  return (
    <Chrome>
      <Switch>
        <Route
          path={`${match.path}/:userId`}
          render={props => <UserDetail  {...props} user={user} />}
        />

        <Route
          exact
          path={match.path}
          render={props => <UserIndex {...props} setUser={doSetUser} />}
        />
      </Switch>
    </Chrome>
  )
};

export default User;
