import React, { useState, useEffect } from 'react';

import { app as firebase, useFirestoreQuery } from '../../services/firebase';
import { useInputChange } from '../../services/forms';

import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CCollapse,
  CToggler,
  CForm,
  CFormGroup,
  CFade,
  CLabel,
  CSelect,
  CButton,
  CSpinner
} from '@coreui/react';

import CIcon from '@coreui/icons-react'

// For now these are hard-coded.  In the future, they should be moved to the
// backend and made dynamically
const ReportTypes = [
  {
    id: 'eventTicketsDaily',
    title: 'Sales by Ticket Price by Day',
  },{
    id: 'eventSpend',
    title: 'Total Activity by Show',
  },{
    id: 'eventRewardItems',
    title: 'Reward Items by Show',
  },{
    id: 'eventChats',
    title: 'Chat Messages by Show',
  },{
    id: 'eventVideoMessages',
    title: 'Video Messages by Show',
  },{
    id: 'eventShippableItems',
    title: 'Shippable Items by Show'
  }
];

const INITIAL_FORM_STATE = {
  reportType: '',
  eventId: '',
  excludeMaxUsers: '1'
};

const loadReport = async (form) => {  
  const runReport = firebase
    .functions()
    .httpsCallable('reports-runReport');

  if (!form.reportType) {
    alert('Please select a report type');
    return;
  }

  if (!form.eventId) {
    alert('Please select an event');
    return;
  }

  const results = await runReport({
    reportType: form.reportType,
    eventId: form.eventId,
    excludeMaxUsers: form.excludeMaxUsers === "1" ? true : false
  });

  // Convert null/undefined to empty string
  for (const idx in results.data.raw.rows) {
    const row = results.data.raw.rows[idx];
    for (const header of results.data.raw.headers) {
      if (row[header] === null || row[header] === undefined) {
        row[header] = "";
      }
    }
  }

  const report = {
    reportType: form.reportType,
    eventId: form.eventId,
    ...results.data
  };

  return report;
}

const SubmitButton = (props) => {
  let text = "Generate Report";
  if (props.isLoading) {
    text = "Loading...";
  }

  return (
    <CButton
      type="submit"
      size="sm"
      color="info"
      disabled={props.isLoading}>
      {
        props.isLoading ?
        <CSpinner size="sm" style={{marginRight: '10px'}}/> : ""
      }
      {text}
    </CButton>
  )
};

const FormSummary = ({visible, data}) => {
  if (visible) {
    let reportName = '';
    for (const reportType of ReportTypes) {
      if (reportType.id == data.reportType) {
        reportName = reportType.title;
      }
    }

    return (
      <CFade className="mb-0">
        <strong>{reportName}</strong> for <strong>Event Name</strong>
      </CFade>);
  }
  return (<></>);
}

const ReportControls = ({ setReport, loading, setLoading }) => {
  const [showCardBody, setShowCardBody] = useState(true);
  const [formVisible, setFormVisible] = useState(true);
  const [form, updateForm] = useInputChange(INITIAL_FORM_STATE);
  
  const query = firebase
    .firestore()
    .collection('events')
    .orderBy('title');
  
  const events = useFirestoreQuery(query);

  const submitForm = async (evt) => {
    evt.preventDefault();
    
    setLoading(true);
    try {
      const report = await loadReport(form);
      setReport(report);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  return (
    <CCard>

      <CCardHeader>
        <strong>Report Settings</strong>
        <CToggler
          inHeader
          className="card-header-actions m-0 d-lg-none"
          onClick={() => setShowCardBody(!showCardBody)}
        >
          {showCardBody ? <CIcon name="cil-chevron-top" /> : <CIcon name="cil-chevron-bottom" />}
        </CToggler>
      </CCardHeader>

      <CCardBody>
        <CCollapse
          show={showCardBody}
          onTransitionEnd={(evt) => setFormVisible(evt.target.offsetHeight > 0)}
        >
          <CForm onSubmit={submitForm}>
            
            <CFormGroup>
              <CLabel className="col-form-label">Report Type</CLabel>
              <CSelect
                name="reportType"
                value={form.reportType}
                onChange={updateForm}
              >
                <option
                  key="default"
                  value=""
                  disabled
                >
                  Select Report Type
                </option>

                {ReportTypes.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                  >
                    {item.title}
                  </option>
                ))}

              </CSelect>
            </CFormGroup>

            <CFormGroup>
              <CLabel className="col-form-label">Event</CLabel>
              <CSelect
                name="eventId"
                value={form.eventId}
                onChange={updateForm}
              >
                <option
                  key="default"
                  value=""
                  disabled
                >
                  {events.data ? 'Select Event' : 'Loading events...'}
                </option>

                {events.data ? events.data.map((item) => (
                  <option key={item.id} value={item.id}>{item.title}</option>
                )) : ''}
              </CSelect>
            </CFormGroup>

            <CFormGroup>
              <CLabel className="col-form-label">Exclude MAX Users?</CLabel>
              <CSelect
                name="excludeMaxUsers"
                value={form.excludeMaxUsers}
                onChange={updateForm}
              >
                <option key="1" value="1">Yes, exclude MAX users from report</option>
                <option key="0" value="0">No, include MAX users in report</option>
              </CSelect>
            </CFormGroup>

            <CFormGroup className="form-actions mb-0 pt-2">
              <SubmitButton isLoading={loading} />
            </CFormGroup>

          </CForm>
        </CCollapse>
        
        <FormSummary visible={!formVisible} data={form} />

      </CCardBody>

      <CCardFooter>
        <p className="note" style={{margin: "10px 0"}}><small>All times are <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/search?q=%22America/Chicago%22+timezone">Central</a>.</small></p>
      </CCardFooter>

    </CCard>
  );
};

export default ReportControls;
